import React from "react";
import { Container, Header, Grid, Segment } from "semantic-ui-react";
import Layout from "../components/Layout";
import Image from "../components/about/image";
import SEO from "../components/seo";

const AboutPage = () => (
  <Layout activePage="about">
    <SEO title="O usłudze" />
    <Segment
      vertical
      style={{
        background: "none",
        margin: "0 0 0 0",
        minHeight: "100%",
      }}
    >
      <Container text>
        <Header
          as="h1"
          content="O USŁUDZE"
          style={{
            margin: "1em 0 1em 0",
            textAlign: "center",
          }}
        />
      </Container>
      <Container>
        <Grid>
          <Grid.Column mobile={16} tablet={12} computer={12}>
            <p>
              Żyjemy w czasach, w których smartfon, laptop czy tablet są nie
              tylko formą rozrywki, ale również narzędziem pracy. Awaria jednego
              z tych urządzeń może przysporzyć nam nie lada problemów.
            </p>
            <p>
              <i>Doradca Informatyczny</i> to <b>nowoczesna usługa</b>, dzięki
              której możesz skorzystać z pomocy Specjalisty IT bez wychodzenia z
              domu. Korzystając ze zdalnej pomocy Zespołu Ekspertów Technicznych
              masz pewność, że Twój problem z urządzeniem zostanie rozwiązany.
            </p>
            <p>
              Poprzez zaawansowane rozwiązania techniczne,<b> Specjaliści IT</b>{" "}
              mogą naprawić problem informatyczny łącząc się zdalnie z
              urządzeniem Klienta. To szybsza i tańsza alternatywa dla
              tradycyjnych usług i nie wymaga pozostawienia urządzenia w
              serwisie.
            </p>
            <p>
              Współpracujemy wyłącznie z doświadczonymi
              <b> informatykami, analitykami i programistami</b>. W ramach
              usługi, Eksperci Techniczni naprawiają takie urządzenia jak:
              komputer stacjonarny, laptop, ultrabook, notebook, smartfon oraz
              Mac.
            </p>
            <p>
              W ramach współpracy z Medicover Benefits, oferujemy trzy
              zróżnicowane pakiety pomocy informatycznej na atrakcyjnych
              warunkach cenowych. Infolinia Doradcy Informatycznego czynna jest
              od
              <b> poniedziałku do piątku, w godzinach od 8:00 do 20:00</b>.
            </p>
          </Grid.Column>
          <Grid.Column tablet={4} computer={4} only="computer tablet">
            <Image />
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  </Layout>
);

export default AboutPage;
